import React from "react";

 const Footer = (props) => {
    return (
       <footer>
           <span>  Mashrur @ All Rights Reserved 2019  </span>
           <span> Created with React &amp; SCSS</span>
       </footer>
    );
};

export default Footer;