import React from "react";


const ProfileCard = (props) => {

    console.log(props.profileInfo.picture);

    const socialLinks =  props.profileInfo.socialLinks.map( (socialLink,i) => {
        return (<li key={i}><a href={ socialLink.url }><i className={ socialLink.icon }></i></a></li>);
    });

    return (
        <div className="profile-card">
            <img width="195px" className="img-circle" src={ props.profileInfo.picture } alt="" />
            <h2 className="text-center">{ props.profileInfo.firstName } { props.profileInfo.middleName }. { props.profileInfo.lastName }</h2>
            <p className="text-muted">{ props.profileInfo.label }</p>
            <ul className="social-links">
                { socialLinks }
            </ul>
        </div>
    );


}

export default ProfileCard;