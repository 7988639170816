import React from "react";

const Profile = (props) => {

    const skills = props.skills.map( (skill,i) => {
        return (
            <div className="skill">
                <h4>{ skill.name }</h4>
                <img width="32" src={ skill.img } alt={skill.name.toLowerCase()} />
                <div className="progress">
                    <div className="progress-bar" style={{ width: skill.progress }}></div>
                </div>
            </div>
        )
    });

    return (
        <div className="box">
            <div className="profile">
                <h1> About</h1>
                <p className="description">{ props.profile.description }</p>
                <div className="hire"><a onClick={  props.handleHireMe } href="mailto:mashru_uk@hotmail.com">Hire Me</a></div>
                <hr />
                <div className="pro-skills">
                    <h2>Professional Skills</h2>
                    <div className="skills">
                        <div className="skills">{ skills }</div>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default Profile;