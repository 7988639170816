import React from "react";

const DownloadButton = (props) => {
    return (
        <div className="download">
            <button className="button-fill" href="">Download CV</button>
        </div>
    );
}

export default DownloadButton;