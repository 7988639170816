import React from "react";

const Experience = (props) => {
    return (
        <div className="box">
            <div className="workExp">
                <h1>WORK EXPERIENCE</h1>
            <div className="time-line">
                <div className="tl-item"><span className="tl-date"> Jan, 2020 - Present</span><h3
                    className="tl-role"> Lead Web Developer | Smart TV Developer </h3>
                    <div className="tl-logo">
                        <img width="60" src="https://images-eu.ssl-images-amazon.com/images/I/810kh9O6GUL.png" alt="" />
                    </div>
                    <div className="tl-inst text-muted"> World Channel Network ltd, London</div>
                        <p>
                            Building Wordpress
                            Working on multiple complex enterprise level WordPress sites with a wide array of technologies
                            and tools covering PHP, MySQL, HTML, CSS, SCSS, GIT, GULP and JavaScript and its frameworks such
                            as jQuery /React.js.<br />Developing custom Themes and bespoke plugins in Wordpress.<br />Integration
                            of third-party services with REST API.
                        </p>
                    </div>
                <div className="tl-item"><span className="tl-date"> June - Nov, 2019</span>
                    <h3 className="tl-role"> Web Developer</h3>
                    <div className="tl-logo">
                        <img width="120" src="https://www.ignitehospitality.com/wp-content/themes/ignite-2017/img/Logo.svg" alt="" />
                    </div>
                    <div className="tl-inst text-muted"> Ignite Hospitality, London</div>
                        <p>
                            Working on multiple complex enterprise level WordPress sites with a wide array of technologies
                            and tools covering PHP, MySQL, HTML, CSS, SCSS, GIT, GULP and JavaScript and its frameworks such
                            as jQuery /React.js.<br />Developing custom Themes and bespoke plugins in Wordpress.<br />Integration
                            of third-party services with REST API.
                        </p>
                    </div>
                <div className="tl-item">
                    <span className="tl-date"> 2016 - May, 2019</span><h3 className="tl-role">Full Stack Developer</h3>
                    <div className="tl-logo">
                        <img width="50" src="https://www.web-epoch.com/wp-content/uploads/2017/09/logo-no-writing-80-e1510744728819.png" alt="" />
                    </div>
                    <div className="tl-inst text-muted"> Web Epoch, London </div>
                    <p>
                        Developing business applications and E-commerce solutions using PHP, Laravel, Vanilla JavaScript,
                        jQuery, Mustache.js, MySQL, Bootstraps, CSS3, WooCommerce, ZenCart, Vue and bespoke cms.
                        <br/>Developing custom Themes and bespoke plugins in Wordpress using WordPress API and Hooks.
                        Integration of third-party services using API.<br />Customising and expending WooCommerce functionality to
                        suit specific business needs.<br/>Writing scripts in PHP and SQL command to manipulate
                        and clean data from different Systems.<br />Collaborating projects and managing source
                        code using Git VCS.<br />Installing and configuring both development and
                        production environment using XAMPP, MAMP and LAMP stack.<br />Using
                        virtualization technology like Vagrant to develop and deploy application.<br />
                        Using git for version control and team collaboration.<br />
                        Working on a multiple projects at a time while making sure I effectively communicate with all the stakeholders.<br />
                        Managing interns, delegating tasks and training them.
                    </p>

                </div>
                <div className="tl-item">
                    <span className="tl-date"> 2014 - 2016 </span>
                    <h3 className="tl-role"> Junior Web Developer &amp; IT Technician </h3>
                    <div className="tl-logo">
                        <img width="200" src="https://www.solutionsepoch.com/images/mainlogo.fw.png" alt="" />
                    </div>
                    <div className="tl-inst text-muted"> Solution Epoch Ltd, London</div>
                    <p>
                        Build engaging and interactive web pages for WordPress CMS from PSDs, that are optimized to be
                        mobile responsive.<br />Create Email campaigns and landing pages with HTML, CSS , jQuery and
                        Bootstrap.<br />Administrate and Microsoft Windows Server Echo system which include Active
                        Directory, Microsoft Exchange, Remote Desktop Server.<br />Server or systems
                        administrators maintain the computer networking system in an office environment by
                        tracking server activity, performing upgrades of software, maintaining computer
                        hardware, addressing questions about technical problems, and improving efficiency by
                        evaluating system network functions.<br />Installing and configuring hardware and
                        software components to ensure usability.<br />Troubleshooting hardware and
                        software issues.<br />Managing technical documentation.<br />Configuring anf
                        conducting daily backup operations.
                    </p>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Experience;