import React from "react";

const Education = (props) => {
    return (
        <div className="box">
            <div className="education">
                <h1>Education</h1>
                <div className="time-line">
                    <div className="tl-item">
                        <span className="tl-date"> 2010 - 2014</span>
                        <h3 className="tl-role">BSc in Computer Science</h3>
                        <div className="tl-logo">
                            <img width="80" src="https://upload.wikimedia.org/wikipedia/en/4/43/Middlesex_University_Logo.png" alt="" />
                            </div>
                        <div className="tl-inst text-muted"> Middlesex University, London</div>
                        <p>
                            Distributed Systems and Networking, Web Applications and Databases, Object Oriented
                            Programming (Java), Database Management Systems, Software Development Projects, Computer
                            Graphics, Human Factors in Design
                        </p>
                    </div>
                    <div className="tl-item">
                        <span className="tl-date"> 2008 - 2010 </span>
                        <h3 className="tl-role"> BTEC National Certificate for IT Practitioner (Computer Networking) </h3>
                        <div className="tl-logo">
                            <img width="150" src="https://upload.wikimedia.org/wikipedia/en/5/5f/NelCollLogo.jpg" alt="" />
                        </div>
                        <div className="tl-inst text-muted">
                            College of Haringey, Enfield and North East London, London
                        </div>
                        <p>
                            Computer Systems, Communication Technologies, Principle of Computer Network, Advanced
                            Spreadsheet Skills, Client Side customisation of web pages, Developing Computer Games,
                            Principle of Software Design and Development, Event Driven Programming, Installing and
                            Upgrading Software, Computer Animation, IT system troubleshoot and repair.
                        </p>
                    </div>
                    <div className="tl-item">
                        <span className="tl-date"> 2005 - 2006   </span>
                        <h3 className="tl-role"> BTEC Intermediate In Business </h3>
                        <div className="tl-logo">
                            <img width="100" src="http://mashrur.co.uk/img/Barnet-Southgate-HH-(2).png" alt="" />
                        </div>
                        <div className="tl-inst text-muted"> Barnet and Southgate College, Southgate, London</div>
                        <p>Business Finance, Business Development, Consumer Protection, Customer Service.</p>
                    </div>
                    <div className="tl-item">
                        <span className="tl-date"> 2004 - 2005 </span>
                        <h3 className="tl-role"> BTEC Foundation In Business </h3>
                        <div className="tl-logo">
                            <img width="100" src="http://mashrur.co.uk/img/Barnet-Southgate-HH-(2).png" alt="" />
                        </div>
                        <div className="tl-inst text-muted">
                            Barnet and Southgate College, Southgate, London
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Education;
