import React from "react";

const Portfolio = (props) => {

    return  (
        <div className="box">
            <div className="portfolio">
                <h1>Portfolio</h1>
                <div className="articles">
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_homek.png" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_ar.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_inayah.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_hhugs.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_justiceForum.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_planItIn.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_snw.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_quaglinos.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_dnd.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_pphe.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_solace.jpg" /></article>
                    <article><img src="http://mashrur.co.uk/img/portfolio/p_habibi.jpg" /></article>
                </div>
            </div>
        </div>
    );

}

export default Portfolio;