import React from 'react';
import './App.css';
import ProfileCard from "./components/ProfileCard";
import DownloadButton from "./components/DownloadButton";
import Profile from "./components/Profile";
import Portfolio from "./components/Portfolio";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Education from "./components/Education";




class App extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
        profileInfo: {
            firstName: "Mashrur",
            middleName: "A",
            lastName: "Chowdhury",
            label: "Full Stack Developer",
            picture: "http://mashrur.co.uk/img/IMAG0041.jpg",
            email: "mashru_uk@hotmail.com",
            phone: "+447716079021",
            website: "http://Mashrur.co.uk",
            intro: "Experienced Web Developer with a demonstrated history of working in the commercial and service industry. Skilled in PHP, Laravel, Wordpress plugins and theme development, jQuery, React and Git. Knowledge in UX and UI with a Bachelor’s Degree focused on Computer Science from Middlesex University. ",
            socialLinks: [
                {
                    name: "git",
                    url: "https://github.com/mashrur786",
                    icon: "fab fa-git-square"
                },
                {
                    name: "linkedin",
                    url: "https://www.linkedin.com/in/mashrur-chowdhury-797733103/",
                    icon: "fab fa-linkedin"
                }
            ],
            location: {
                address: "London",
                postalCode: "N15",
                city: "London",
                countryCode: "GB",
                region: "Middlesex"
            }

        },
        profile: {
            description: "Experienced Web Developer with a demonstrated history of working in the commercial and service industry. Skilled in PHP, Laravel, Wordpress plugins and theme development, jQuery, React and Git. Knowledge in UX and UI with a Bachelor’s Degree focused on Computer Science from Middlesex University.",
        },
        skills: [
            {
                name: "PHP",
                img: "https://www.php.net/images/logos/php-logo-bigger.png",
                imgWidth: "52",
                progress: "95%"
            },
            {
                name: "MySql",
                img: "https://logodix.com/logo/840687.png",
                imgWidth: "52",
                progress: "85%"
            },
            {
                name: "WordPress",
                img: "http://www.pngall.com/wp-content/uploads/2016/05/WordPress-Logo-PNG-HD.png",
                imgWidth: "32",
                progress: "95%"
            },
            {
                name: "WooCommerce",
                img: "//upload.wikimedia.org/wikipedia/commons/thumb/2/2a/WooCommerce_logo.svg/250px-WooCommerce_logo.svg.png",
                imgWidth: "52",
                progress: "95%"
            },
            {
                name: "Laravel",
                img: "https://laravel.com/img/logomark.min.svg",
                imgWidth: "52",
                progress: "70%"
            },
            {
                name: "Javascript",
                img: "https://upload.wikimedia.org/wikipedia/commons/9/99/Unofficial_JavaScript_logo_2.svg",
                imgWidth: "32",
                progress: "90%"
            },
            {
                name: "Javascript ES6",
                img: "https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/es6/es6.png",
                imgWidth: "34",
                progress: "90%"
            },
            {
                name: "React.js",
                img: "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
                imgWidth: "32",
                progress: "80%"
            },
            {
                name: "HTML5",
                img: "https://www.w3.org/html/logo/downloads/HTML5_Badge_32.png",
                imgWidth: "32",
                progress: "100%"
            },
            {
                name: "CSS3",
                img: "https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/121-css3-512.png",
                imgWidth: "32",
                progress: "99%"
            },
            {
                name: "Bootstrap Framework",
                img: "https://www.drupal.org/files/styles/grid-3-2x/public/project-images/bootstrap-stack.png?itok=BdTnonBB",
                imgWidth: "32",
                progress: "99%"
            },
            {
                name: "JQuery",
                img: "http://pluspng.com/img-png/jquery-logo-png--800.gif",
                imgWidth: "32",
                progress: "99%"
            },
            {
                name: "SCSS",
                img: "https://upload.wikimedia.org/wikipedia/commons/9/96/Sass_Logo_Color.svg",
                imgWidth: "32",
                progress: "80%"
            },
            {
                name: "GIT",
                img: "https://garygregory.files.wordpress.com/2016/11/git_logo.png?w=150",
                imgWidth: "32",
                progress: "80%"
            },
            {
                name: "Node.js",
                img: "https://img.icons8.com/color/480/nodejs.png",
                imgWidth: "32",
                progress: "50%"
            },
            {
                name: "Vue.js",
                img: "https://vuejs.org/images/logo.png",
                imgWidth: "32",
                progress: "40%"
            },
            {
                name: "Angular",
                img: "https://angular.io/assets/images/logos/angular/angular.svg",
                imgWidth: "32",
                progress: "10%"
            },
            {
                name: "Java",
                img: "http://www.victoryinfotech.com/wp-content/uploads/2017/06/java-logo-png-300x300.png",
                imgWidth: "32",
                progress: "10%"
            },

        ],
        portfolio: [],
        workExperiences: [],
        education: [
            {
                date: "2010 - 2014",
                course: "BSc in Computer Science",
                schoolLogo: "https://upload.wikimedia.org/wikipedia/en/4/43/Middlesex_University_Logo.png",
                school: "Middlesex University, London",
                courseDetails: "Distributed Systems and Networking, Web Applications and Databases, Object Oriented\n" +
                    "                            Programming (Java), Database Management Systems, Software Development Projects, Computer\n" +
                    "                            Graphics, Human Factors in Design"
            },
            {
                date: "2008 - 2010",
                course: " BTEC National Certificate for IT Practitioner (Computer Networking) ",
                schoolLogo: "https://upload.wikimedia.org/wikipedia/en/4/43/Middlesex_University_Logo.png",
                school: "Middlesex University, London",
                courseDetails: "Distributed Systems and Networking, Web Applications and Databases, Object Oriented\n" +
                    "                            Programming (Java), Database Management Systems, Software Development Projects, Computer\n" +
                    "                            Graphics, Human Factors in Design"
            },

        ]


    }
    //eof state object

  };

  handleHireMe = (e) => {

      alert("hire me buck");
      e.preventDefault();

  };

  render() {
    return (
        <div className="container">
          <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 profile-card-container">
                <aside>
                    <ProfileCard profileInfo={ this.state.profileInfo } />
                    <DownloadButton/>
                </aside>
              </div>
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                 <main>
                    <Profile profile={this.state.profile} handleHireMe={ this.handleHireMe }  skills={ this.state.skills }/>
                    <Portfolio />
                    <Experience />
                    <Education />
                 </main>
               </div>
          </div>
          <Footer />
        </div>
    )
  }


}

export default App;
